// import React from "react";
// import styled from "styled-components";
// import pic from "./ASSESTS/AndelaLogo.svg";

// const Header = () => {
//   return (
//     <div>
//       <Container>
//         <LogoWrap>
//           <Logo src={pic} />
//         </LogoWrap>
//         <NavHold>
//           <Nav>For Technology Experts</Nav>
//           <Nav> Business</Nav>
//           <Nav>Enterprise</Nav>
//           <Nav>About Us</Nav>
//         </NavHold>
//         <ButtonHold>
//           <Button bd="1px solid black" bg="" wd="150px">
//             Apply for Jobs
//           </Button>
//           <Button bd="" bg="lightgreen" wd="120px">
//             Hire Talents
//           </Button>
//         </ButtonHold>
//       </Container>
//     </div>
//   );
// };

// export default Header;

// const LogoWrap = styled.div``;

// const Logo = styled.img`
//   height: 50px;
//   margin-right: 50px;
// `;
// const Nav = styled.div`
//   margin-left: 40px;
//   font-size: 20px;
//   color: black;
//   font-weight: 400;
// `;

// const ButtonHold = styled.div`
//   display: flex;
// `;
// const Button = styled.div<{ wd: string; bg: string; bd: string }>`
//   width: ${(props) => props.wd};
//   height: 40px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border: ${(props) => props.bd};
//   background-color: ${(props) => props.bg};
//   border-radius: 50px;
//   cursor: pointer;
//   margin-right: 20px;

//   :hover {
//     transform: scale(0.97);
//     transition: all 350ms;
//   }
// `;

// const NavHold = styled.div`
//   display: flex;
//   align-items: center;
//   margin-right: 40px;
//   /* background-color: blue; */
// `;

// const Holder = styled.div``;

// const Container = styled.div`
//   width: 100%;
//   height: 100px;
//   background-color: white;
//   box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
//     rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;
import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
// import image from "./ASSESTS/header.png";
import images from "./ASSESTS/AndelaLogo.svg";
import images2 from "./ASSESTS/AndelaLogo2.svg";

interface props {
  bg: string;
}

const Header: React.FC<props> = ({ bg }) => {
  const [show, setShow] = React.useState<Boolean>(false);

  const changeNav = () => {
    if (window.scrollY >= 70) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  window.addEventListener("scroll", changeNav);

  return (
    <Container bg={bg}>
      {show ? (
        <Head bg="white">
          <Right>
            <Images src={images2} />
          </Right>
          <Mid>
            <Navs to="/pageone" col="black">
              For Technology Experts
            </Navs>
            <Navs to="/pagetwo" col="black">
              Business
            </Navs>
            <Navs to="" col="black">
              Enterprise
            </Navs>
            <Navs to="/pagethree" col="black">
              About Us
            </Navs>
          </Mid>
          <Lef>
            <Button2 cl="black" wd="180px" br="1px solid black" bc="">
              <Text>Apply for Jobs</Text>
            </Button2>
            <Button2 cl="" wd="150px" br="" bc="#56C870">
              <Text1>Hire Talents</Text1>
            </Button2>
          </Lef>
        </Head>
      ) : (
        <Head bg="">
          <Right>
            <Images src={images} />
          </Right>
          <Mid>
            <Navs to="/pageone" col="white">
              For Technology Experts
            </Navs>
            <Navs to="/pagetwo" col="white">
              Business
            </Navs>
            <Navs to="" col="white">
              Enterprise
            </Navs>
            <Navs to="/pagethree" col="white">
              About Us
            </Navs>
          </Mid>
          <Lef>
            <Button2 cl="white" wd="180px" br="1px solid white" bc="">
              <Text>Apply for Jobs</Text>
            </Button2>
            <Button2 cl="" wd="150px" br="" bc="#56C870">
              <Text1>Hire Talents</Text1>
            </Button2>
          </Lef>
        </Head>
      )}
    </Container>
  );
};

export default Header;

const Text = styled.div`
  font-size: 19px;
  /* color: white; */
`;
const Text1 = styled.div`
  font-size: 20px;
`;

const Button2 = styled.div<{ wd: string; br: string; bc: string; cl: string }>`
  width: ${(props) => props.wd};
  height: 40px;
  border-radius: 50px;
  border: ${(props) => props.br};
  background-color: ${(props) => props.bc};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
  cursor: pointer;
  color: ${(props) => props.cl};
`;

const Navs = styled(NavLink)<{ col: string }>`
  font-size: 20px;
  margin-left: 60px;
  /* color: white; */
  display: flex;
  text-decoration: none;
  color: ${(props) => props.col};

  cursor: pointer;
  :hover {
    transform: scale(0.95);
  }
`;

const Mid = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  width: 190px;
  height: 100%;
  align-items: center;
  display: flex;
  margin-left: 30px;
`;

const Head = styled.div<{ bg: string }>`
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  z-index: 99999999999;
  position: fixed;

  background-color: ${(props) => props.bg};
`;

const Images = styled.img``;

const Lef = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 40px;
`;
// url(${image})
const Container = styled.div<{ bg: string }>`
  height: 100px;
  width: 100%;
  background-color: rgba(10%, 21%, 21%, 0.9);
  background-image: ${(props) => props.bg};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  position: relative;
  background-attachment: fixed;
`;
