import React from "react";
import styled from "styled-components";
import bgpic from "../ASSESTS/people.png";

const People = () => {
  return (
    <div>
      <Container>
        <Text>
          <h1>Grow your potential beyond borders</h1>
          <p>
            Connect with a global network and land international opportunities.
          </p>
          <Button wd="250px" br="" bc="#56C870">
            <Tex>Discover Opportunities</Tex>
          </Button>
        </Text>
      </Container>
    </div>
  );
};

export default People;

const Container = styled.div`
  font-family: "Inria Serif", serif;
  width: 100%;
  height: 400px;
  background-color: black;
  background-image: url(${bgpic});
  color: white;

  display: flex;
  align-items: center;
  z-index: 10;
  position: relative;

  ::before {
    content: "";
    width: 100%;
    height: 400px;
    background-color: rgba(10%, 21%, 21%, 0.8);
    position: absolute;
  }
`;

const Text = styled.div`
  position: relative;
  margin-left: 30px;

  h1 {
    font-size: 40px;
  }
  p {
    font-size: 18px;
  }
`;

const Button = styled.div<{ wd: string; br: string; bc: string }>`
  width: ${(props) => props.wd};
  height: 70px;
  border-radius: 50px;
  border: ${(props) => props.br};
  background-color: ${(props) => props.bc};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 60px;
  color: black;

  :hover {
    transform: scale(0.95);
    transition: all ease-in-out 450ms;
    margin-left: 30px;
  }
`;

const Tex = styled.div`
  font-size: 16px;
`;
