import React from "react";
import styled from "styled-components";
import pic1 from "../ASSESTS/github_logo.svg";
import pic2 from "../ASSESTS/invision_logo.svg";
import pic3 from "../ASSESTS/coursera_logo.svg";
import pic4 from "../ASSESTS/Kraft_Heinz_Logo.svg";
import pic5 from "../ASSESTS/seismic_logo.svg";
import pic6 from "../ASSESTS/goldman_sachs_logo.svg";
import pic7 from "../ASSESTS/jamf.png";
import pic8 from "../ASSESTS/pluralsight.png";
import pic9 from "../ASSESTS/viacomcbs.png";
import pic10 from "../ASSESTS/mastercard.png";
import pic11 from "../ASSESTS/casper.png";
import pic12 from "../ASSESTS/mindshare.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Comp2Slider = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 5.5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    // autoplaySpeed: 5000,
    cssEase: "linear",
  };

  return (
    <div>
      <Container>
        <Holder>
          <TopText>We’re trusted by the best</TopText>
          <Wrapper>
            <Slider {...settings}>
              <Image src={pic1} />
              <Image src={pic2} />
              <Image src={pic8} />
              <Image src={pic4} />
              <Image src={pic5} />
              <Image src={pic6} />
              <Image src={pic7} />
              <Image src={pic3} />
              <Image src={pic10} />
              <Image src={pic9} />
              <Image src={pic11} />
              <Image src={pic12} />
            </Slider>
          </Wrapper>
        </Holder>
      </Container>
    </div>
  );
};

export default Comp2Slider;

const Holder = styled.div`
  /* background-color: rebeccapurple; */
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Container = styled.div`
  width: 100%;
  height: 250px;
  background-color: aliceblue;
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  align-items: center;
`;

const TopText = styled.div`
  font-family: "Inria Serif", "Garamond", serif;
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 40px;
`;

const Wrapper = styled.div`
  /* display: flex; */
  overflow: hidden;
  /* justify-content: space-between; */
  /* align-items: center; */
  width: 95%;
  margin-top: 10px;
`;
const Image = styled.img`
  /* width: 120px; */
  height: 30px;
  margin-left: 20px;
`;
