import React from "react";
import styled from "styled-components";
import pic1 from "../ASSESTS/imgone.png";
import pic2 from "../ASSESTS/imgtwo.png";
import pic3 from "../ASSESTS/imgthree.png";
import { AiOutlineCheck } from "react-icons/ai";
const Career = () => {
  return (
    <div>
      <Container>
        <h1>Find what you’re looking for and more</h1>
        <Holder>
          <DivOne>
            <ImgHold>
              <Img src={pic1} />
            </ImgHold>
            <TextHold>
              <h1>Grow your career with us</h1>
              <NavHold>
                <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  Find meaningful, long-term work with interesting organizations
                </Nav>
                <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  Work with an internationally distributed team and company
                </Nav>
                <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  Gain additional skills through exclusive learning
                  opportunities
                </Nav>
                <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  Network with other technologists to develop your expertise
                </Nav>
              </NavHold>
            </TextHold>
          </DivOne>
          <DivTwo>
            <TextHold>
              <h1>Set your own rate</h1>
              <NavHold>
                <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  Only work with companies we trust
                </Nav>
                <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  Receive payments on time in your local currency, USD, or in
                  Crypto
                </Nav>
                <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  Discover salaries that match your skills and experience
                </Nav>
              </NavHold>
            </TextHold>
            <ImgHold>
              <Img src={pic2} />
            </ImgHold>
          </DivTwo>
          <DivThree>
            <ImgHold>
              <Img src={pic3} />
            </ImgHold>
            <TextHold>
              <h1>Work anytime, anywhere</h1>
              <NavHold>
                <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  Ditch the commute and work from anywhere
                </Nav>
                <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  With roles across different time zones, you choose when you
                  work
                </Nav>
                <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  Create a healthy, flexible work-life balance
                </Nav>
              </NavHold>
            </TextHold>
          </DivThree>
        </Holder>
      </Container>
    </div>
  );
};

export default Career;
const Icon = styled.div`
  color: green;
  font-weight: bold;
  margin-right: 5px;
`;

const Nav = styled.div`
  font-size: 20px;
  display: flex;
  line-height: 50px;
`;

const NavHold = styled.div``;

const TextHold = styled.div`
  width: 45%;
  height: 100%;
  /* background-color: purple; */
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const ImgHold = styled.div`
  width: 50%;
  height: 100%;
  /* background-color: rebeccapurple; */
`;

const Container = styled.div`
  width: 100%;
  /* height: 1400px; */
  background-color: white;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-family: "Inria Serif", serif;

  h1 {
    font-weight: 500;
    font-size: 42px;
    margin-bottom: 30px;
  }
`;

const Holder = styled.div`
  width: 95%;
  height: 100%;
`;

const DivOne = styled.div`
  width: 100%;
  height: 400px;
  /* background-color: blue; */
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
`;

const DivTwo = styled.div`
  width: 100%;
  height: 400px;
  /* background-color: royalblue; */
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
`;

const DivThree = styled.div`
  width: 100%;
  height: 400px;
  /* background-color: cadetblue; */

  display: flex;
  justify-content: space-between;
`;
