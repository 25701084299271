import React from "react";
import styled from "styled-components";
import bg from "../ASSESTS/smile.png";

const Comp5 = () => {
  return (
    <div>
      <Container>
        <Holder>
          <DivOne>
            <h1>
              Spark a <br /> thousand <br /> connections
            </h1>
            <p>Becoming part of a community has never been easier.</p>
            <Button wd="180px" br="" bc="#56C870">
              <Tex>Get Started</Tex>
            </Button>
            {/* <p>Get the Enterprise Advantage &gt;</p> */}
          </DivOne>
          <DivTwo>
            <Icon>
              <Circle>
                <p>1</p>
              </Circle>

              <Line></Line>

              <Circle>
                <p>2</p>
              </Circle>
              <Line></Line>
              <Circle>
                <p>3</p>
              </Circle>
              {/* <Line></Line> */}
            </Icon>
            <Txt>
              <h1>Demonstrate your skills</h1>
              <p>
                Join our expert community by completing a short assessment to
                showcase your skills.
              </p>
              <h1>Personalized matching</h1>
              <p>
                You’ve done the work to build your experience and knowledge. Now
                we’ll find your dream role through enhancing your profile,
                career coaching, and introducing you to the world’s most
                innovative companies.
              </p>
              <h1>Unlock your potential</h1>
              <p>
                Once you land your perfect role, Andela is here to support you
                to hone your craft and achieve your goals.
              </p>
            </Txt>
          </DivTwo>
        </Holder>
      </Container>
    </div>
  );
};

export default Comp5;

const Txt = styled.div`
  position: relative;

  margin-left: 30px;
  h1 {
    color: white;
    font-size: 22px;
  }

  p {
    color: white;
    margin-bottom: 60px;
  }
`;

const Icon = styled.div`
  /* background-color: blue; */
`;

const Line = styled.div`
  width: 2px;
  height: 100px;
  margin-left: 35px;
  background-color: white;
  position: relative;
`;

const Circle = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid white;
  background-color: transparent;
  position: relative;

  p {
    position: absolute;
    margin-top: 15px;
    margin-left: 25px;

    font-size: xx-large;
    color: white;
  }
`;

const Tex = styled.div`
  font-size: 16px;
`;

const Button = styled.div<{ wd: string; br: string; bc: string }>`
  width: ${(props) => props.wd};
  height: 70px;
  border-radius: 50px;
  border: ${(props) => props.br};
  background-color: ${(props) => props.bc};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 60px;

  :hover {
    transform: scale(0.95);
    transition: all ease-in-out 450ms;
  }
`;

const DivTwo = styled.div`
  width: 40%;
  height: 100%;
  margin-right: 20px;
  display: flex;
  position: relative;

  /* background-color: cyan; */
`;

const DivOne = styled.div`
  width: 50%;
  height: 100%;
  margin-left: 20px;
  position: relative;

  h1 {
    color: white;
    font-size: 50px;
    font-weight: 100;
    font-family: "Inria Serif", serif;
  }

  p {
    color: white;
    font-weight: 100;
    font-size: 18px;
  }
  /* background-color: aqua; */
`;

const Holder = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
  /* background-color: blue; */
  justify-content: space-between;
`;

const Container = styled.div`
  height: 700px;
  width: 100%;
  background-color: black;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  position: relative;
  background-attachment: fixed;
  z-index: 9999;
  position: relative;

  ::before {
    content: "";
    width: 100%;
    height: 700px;
    background-color: rgba(10%, 21%, 21%, 0.7);
    position: absolute;
  }
`;
