import React from "react";
import styled from "styled-components";

import play from "../ASSESTS/andela_video.png";

const VideoComp = () => {
  return (
    <div>
      <Container>
        <Text>
          <h1>Our vibrant community</h1>
          <p>Rizwan Jafri shares his Andela story from Lahore, Pakistan.</p>
        </Text>
        <Holder>
          <VideoImg src={play} />
        </Holder>
      </Container>
    </div>
  );
};

export default VideoComp;

const Container = styled.div`
  width: 100%;
  /* height: 400px; */
  background-color: #173b3f;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Text = styled.div`
  text-align: center;
  color: white;

  h1 {
    font-size: 40px;
  }
`;

const Holder = styled.div`
  width: 60%;
  /* height: 200px; */
`;

const VideoImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-bottom: 50px;
  cursor: pointer;
  :hover {
    transform: scale(0.97);
  }
`;
