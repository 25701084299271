import React from "react";
import styled from "styled-components";

const Commitment = () => {
  return (
    <div>
      <Holder>
        <Container>
          <DivOne>
            <h1>Our commitment to your success</h1>
          </DivOne>
          <Divtwo>
            <h1>Technologist first culture</h1>
            <p>
              Ensuring a seamless experience for technologists is our top
              priority. You can rest assured there is always someone looking out
              for your interests and career.
            </p>
            <h1>Trusted platform</h1>
            <p>
              We only work with trusted and vetted companies. No matter what,
              we’ll ensure you are paid for the work you do, and on time.
            </p>
            <h1>Global support</h1>
            <p>
              Wherever you are, and whatever issues you may face, we’re here to
              help solve any disputes, fast!
            </p>
          </Divtwo>
        </Container>
      </Holder>
    </div>
  );
};

export default Commitment;

const Holder = styled.div`
  /* font-family: "Poppins", sans-serif; */
  font-family: "Inria Serif", serif;
  width: 100%;
  /* height: 300px; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 95%;
  /* height: 300px; */
  /* background-color: blue; */
  display: flex;
  margin-bottom: 100px;
`;

const DivOne = styled.div`
  //   background-color: royalblue;
  width: 50%;
  height: 100%;
`;

const Divtwo = styled.div`
  //   background-color: cadetblue;

  width: 50%;
  height: 100%;
`;
