import React from "react";
import Header from "../Header";
import Career from "./Career";
import Commitment from "./Commitment";
import Comp5 from "./Comp5";
import Comp6 from "./Comp6";
import Comp8 from "./Comp8";
import Comp9 from "./Comp9";
import Hero2 from "./Hero2";
import Hero from "./Hero2";
import LastPart from "./LastPart";
import People from "./People";
import VideoComp from "./VideoComp";

const PageOneHome = () => {
  return (
    <div>
      <Hero2 />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Comp6 />
      <VideoComp />

      <br />
      <br />
      <br />
      <Career />
      <br />
      <br />
      <Comp5 />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Comp9 />
      <br />
      <br />
      <br />
      <br />
      <br />
      <People />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Commitment />
      <Comp8 />
      <LastPart />
    </div>
  );
};

export default PageOneHome;
