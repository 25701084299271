import React from "react";
import Build from "./Build";
import Comp2Slider from "./Comp2Slider";
import Comp5 from "./Comp5";
import Comp7 from "./Comp7";
import Hero3 from "./Hero3";
import LastPart from "./LastPart";
import PeaceOfMind from "./PeaceOfMind";
import Talent from "./Talent";

const PageTwoHome = () => {
  return (
    <div>
      <Hero3 />
      <Comp2Slider />
      <Talent />
      <Build />
      <PeaceOfMind />
      <Comp5 />
      <Comp7 />
      <LastPart />
    </div>
  );
};

export default PageTwoHome;
