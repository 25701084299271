import React from "react";
import logo from "./logo.svg";
import "./App.css";
import HomeScreen from "./COMPONENTS/HomeScreen";
import Footer from "./COMPONENTS/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Hero2 from "./COMPONENTS/PAGEONE/Hero2";
import Hero from "./COMPONENTS/Hero";
import Header from "./COMPONENTS/Header";
import PageOneHome from "./COMPONENTS/PAGEONE/PageOneHome";
import image from "./COMPONENTS/ASSESTS/header.png";
import PageTwoHome from "./COMPONENTS/PAGETWO/PageTwoHome";
import PageThreeHome from "./COMPONENTS/PAGETHREE/PageThreeHome";
function App() {
  return (
    <div>
      <BrowserRouter>
        <Header bg={image} />
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/pageone" element={<PageOneHome />} />
          <Route path="/pagetwo" element={<PageTwoHome />} />
          <Route path="/pagethree" element={<PageThreeHome />} />
        </Routes>
      </BrowserRouter>

      <Footer />
    </div>
  );
}

export default App;
