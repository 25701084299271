import React from "react";
import styled from "styled-components";
import image from "../ASSESTS/greenwash.png";

const Hero2 = () => {
  return (
    <Container>
      <Top>
        Find work that you <br />
        love with companies <br />
        you can trust
      </Top>
      <P>
        Join the Andela Talent Network to experience the joy of long-term work,{" "}
        <br />
        with vetted companies and competitive compensation.
      </P>
      <Left>
        <Button wd="300px" br="" bc="#56C870">
          <Tex>Apply to access exclusive Jobs</Tex>
        </Button>
        {/* <Button wd="200px" br="" bc="white">
          <Tex1>Apply for Jobs</Tex1>
        </Button> */}
      </Left>
    </Container>
  );
};

export default Hero2;

const Button = styled.div<{ wd: string; br: string; bc: string }>`
  width: ${(props) => props.wd};
  height: 70px;
  border-radius: 50px;
  border: ${(props) => props.br};
  background-color: ${(props) => props.bc};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    transform: scale(0.95);
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  margin-left: 40px;
  margin-top: 60px;
  width: 410px;
  justify-content: space-between;
  z-index: 999;
`;
const P = styled.div`
  color: white;
  margin-left: 40px;
  font-size: 18px;
  z-index: 999;
`;
const Top = styled.h1`
  font-style: italic;
  font-size: 55px;
  font-weight: 600;
  color: white;
  margin-left: 40px;
  /* margin-top: 200px; */
  z-index: 999;
  span {
  }
`;
const Container = styled.div`
  height: 700px;
  width: 100%;
  background-color: black;
  background-image: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  position: relative;
  background-attachment: fixed;
  position: relative;
  z-index: 10;

  ::before {
    content: "";
    width: 100%;
    height: 700px;
    background-color: rgba(10%, 21%, 21%, 0.8);
    position: absolute;
  }
`;
const Tex1 = styled.div`
  font-size: 19px;
`;
const Tex = styled.div`
  font-size: 18px;
`;
