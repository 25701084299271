import React from "react";
import styled from "styled-components";
import image from "../ASSESTS/about.png";
import images from "../ASSESTS/AndelaLogo.svg";
import images2 from "../ASSESTS/AndelaLogo2.svg";

const Hero4 = () => {
  return (
    <Container>
      <Top>
        Our mission is to <br /> connect brilliance <br /> with opportunity
      </Top>
      <P>Irrespective of race, gender, and geography.</P>
      {/* <Left>
        <Button wd="180px" br="" bc="#56C870">
          <Tex>Hire Talents</Tex>
        </Button>
        <Button wd="200px" br="" bc="white">
          <Tex1>Apply for Jobs</Tex1>
        </Button>
      </Left> */}
    </Container>
  );
};

export default Hero4;

const Button = styled.div<{ wd: string; br: string; bc: string }>`
  width: ${(props) => props.wd};
  height: 70px;
  border-radius: 50px;
  border: ${(props) => props.br};
  background-color: ${(props) => props.bc};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  margin-left: 40px;
  margin-top: 60px;
  width: 410px;
  justify-content: space-between;
  z-index: 999;
`;
const P = styled.div`
  color: white;
  margin-left: 40px;
  font-size: 20px;
  z-index: 999;
`;
const Top = styled.h1`
  font-style: italic;
  font-size: 65px;
  font-weight: 600;
  color: white;
  margin-left: 40px;
  /* margin-top: 200px; */
  z-index: 999;
  span {
  }
`;
const Container = styled.div`
  font-family: "Inria Serif", serif;
  height: 700px;
  width: 100%;
  background-color: black;
  background-image: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  position: relative;
  background-attachment: fixed;
`;
const Tex1 = styled.div`
  font-size: 19px;
`;
const Tex = styled.div`
  font-size: 20px;
`;
