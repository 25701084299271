import React from "react";
import Achievements from "./Achievements";
import Empower from "./Empower";
import Hero4 from "./Hero4";
import Investors from "./Investors";
import LastPart from "./LastPart";
import Together from "./Together";
import Transform from "./Transform";

const PageThreeHome = () => {
  return (
    <div>
      <Hero4 />
      <br />
      <br />
      <br />
      <Empower />
      <Together />
      <br />
      <br />
      <br />
      <Investors />
      <Transform />
      <Achievements />
      <LastPart />
    </div>
  );
};

export default PageThreeHome;
