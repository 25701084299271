import React from "react";
import styled from "styled-components";
import build from "../ASSESTS/talentpic.png";

const Build = () => {
  return (
    <div>
      <Container>
        <Holder>
          <DivOne>
            <Img src={build} />
          </DivOne>
          <DivTwo>
            <h3>Build your team today</h3>
            <h1>
              Exceptional <br /> technical talent
            </h1>
            <p>
              Skilled engineers, product managers, and designers at your
              fingertips. An extensive list of technical services to suit your
              business needs. Start building your world-class team faster with
              talent from Andela.
            </p>
            <Button wd="300px" br="" bc="#56C870">
              <Tex>Discover Talent</Tex>
            </Button>
          </DivTwo>
        </Holder>
      </Container>
    </div>
  );
};

export default Build;

const Container = styled.div`
  width: 100%;
  height: 500px;
  background-color: #173b3f;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

const Holder = styled.div`
  width: 90%;
  height: 400px;
  display: flex;
  justify-content: space-between;
`;

const DivOne = styled.div`
  width: 47%;
  height: 100%;
  /* background-color: aqua; */
`;

const DivTwo = styled.div`
  width: 47%;
  height: 100%;
  /* background-color: aqua; */

  color: white;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Button = styled.div<{ wd: string; br: string; bc: string }>`
  width: ${(props) => props.wd};
  height: 70px;
  border-radius: 50px;
  border: ${(props) => props.br};
  background-color: ${(props) => props.bc};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    transform: scale(0.95);
  }
`;

const Tex = styled.div`
  font-size: 18px;
`;
