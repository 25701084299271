import React from "react";
import styled from "styled-components";
import pic1 from "../ASSESTS/picone.png";
import pic2 from "../ASSESTS/pictwo.png";
import pic3 from "../ASSESTS/picthree.png";
import { AiOutlineCheck } from "react-icons/ai";
const Talent = () => {
  return (
    <div>
      <Container>
        <h1>Find what you’re looking for and more</h1>
        <Holder>
          <DivOne>
            <ImgHold>
              <Img src={pic1} />
            </ImgHold>
            <TextHold>
              <h1>Rigorous vetting for talent from multiple regions</h1>
              <NavHold>
                <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  Quick and efficient
                </Nav>
                <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  Only top quality options
                </Nav>
                <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  Stress-free hiring of global talent
                </Nav>
                {/* <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  Network with other technologists to develop your expertise
                </Nav> */}
              </NavHold>
            </TextHold>
          </DivOne>
          <DivTwo>
            <TextHold>
              <h1>The world’s leading companies are already here</h1>
              <NavHold>
                <p>
                  Feel the bustle of the Global Marketplace here at Andela where
                  brilliant minds and companies gather from all over the world.
                </p>
              </NavHold>
            </TextHold>
            <ImgHold>
              <Img src={pic2} />
            </ImgHold>
          </DivTwo>
          <DivThree>
            <ImgHold>
              <Img src={pic3} />
            </ImgHold>
            <TextHold>
              <h1>Scale fast and with ease</h1>
              <NavHold>
                <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  Onboarding is 70% faster
                </Nav>
                <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  We provide additional support
                </Nav>
                <Nav>
                  <Icon>
                    <AiOutlineCheck />
                  </Icon>
                  Get the team you need, regardless of size
                </Nav>
              </NavHold>
            </TextHold>
          </DivThree>
        </Holder>
      </Container>
    </div>
  );
};

export default Talent;

const Icon = styled.div`
  color: green;
  font-weight: bold;
  margin-right: 5px;
`;

const Nav = styled.div`
  font-size: 20px;
  display: flex;
  line-height: 50px;
`;

const NavHold = styled.div`
  p {
    line-height: 30px;
    font-size: 22px;
    font-weight: 100;
  }
`;

const TextHold = styled.div`
  width: 45%;
  height: 100%;
  /* background-color: purple; */
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const ImgHold = styled.div`
  width: 50%;
  height: 100%;
  /* background-color: rebeccapurple; */
`;

const Container = styled.div`
  width: 100%;
  /* height: 1400px; */
  background-color: white;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-family: "Inria Serif", serif;

  h1 {
    font-weight: 500;
    font-size: 42px;
    margin-bottom: 30px;
  }
`;

const Holder = styled.div`
  width: 95%;
  height: 100%;
`;

const DivOne = styled.div`
  width: 100%;
  height: 400px;
  /* background-color: blue; */
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
`;

const DivTwo = styled.div`
  width: 100%;
  height: 400px;
  /* background-color: royalblue; */
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
`;

const DivThree = styled.div`
  width: 100%;
  height: 400px;
  /* background-color: cadetblue; */

  display: flex;
  justify-content: space-between;

  padding-bottom: 30px;
`;
